<template>
	<Form 
		class="container bg-gray-50 max-w-6xl mx-auto md:py-6 md:mt-20 mb-60 sm:px-6 lg:px-8"
		@submit="save"			
		v-slot="{ errors }"
	>
		<img src="@/assets/PMLogo_fekete.png" class="max-w-xs md:max-w-md mx-auto mt-10 md:mt-20" @click="submited = !submited">
	<transition name="fade" mode="out-in">
		<div v-if="!submited" key="form">

		<div class="form-selection-title my-10 md:my-20 max-w-6xl mx-auto p-6 md:p-10 text-xl leading-7 md:leading-8 border-4 border-red-600">
			<strong> 
				Kérjük, hogy a lent található szűrésre alkalmas kérdőívet, mely az Otthonfelújítási támogatással kapcsolatos saját érdekében alaposan olvassa át és a megfelelő adatokkal töltse ki.
				<br>
				A kérdőív kitöltése nem kötelező, segítséget szeretnénk nyújtani a megfelelő szűrési folyamatokhoz!
			</strong>
		</div>
		
			<div class="mt-10 sm:mt-0">
				<div class="md:grid md:grid-cols-3 md:gap-6 mt-6 sm:mt-0">
					<div class="md:col-span-1">
						<div class="px-4 sm:px-0">
							
							<h3 class="text-lg font-medium leading-6 text-gray-900">
								Az igénylő adatai
							</h3>
							<p class="mt-1 text-sm text-gray-600">
								A pályázatot benyújtó személy adatai.
							</p>
						</div>
					</div>
					<div class="mt-5 md:mt-0 md:col-span-2">
						<div class="shadow overflow-hidden sm:rounded-md">
							<div class="px-4 py-5 bg-white space-y-6 sm:p-6 border-amber-500 border-2 rounded-md">
								<div class="grid grid-cols-6 gap-6">
									<div class="col-span-6 sm:col-span-3">
										
										<!-- 
											https://codesandbox.io/s/custom-text-input-with-vee-validate-v4-8k9gy 
											https://vee-validate.logaretm.com/v4/examples/custom-inputs

										<Input
											type="text"
											rules="required" 
											name="fullname"
											id="fullname"
											v-model:fullname="form.fullname"
											placeholder="Pályázó László"
										/> -->
										
										<label for="fullname">Az igénylő teljes neve</label>
										<Field
											type="text"
											name="fullname"
											id="fullname"
											rules="required"
											v-model="form.fullname"
											placeholder="Pályázó László"
											label="Teljes név"
											:class="{ 'has-error': !!errors.fullname}"
										/>
										<ErrorMessage class="error-msg" name="fullname" />
									</div>

									<div class="col-span-6 sm:col-span-4">
										<label for="email">Az igénylő e-mail címe</label>
										<Field
											type="email"
											name="email"
											id="email"
											rules="email" 
											v-model="form.email"
											placeholder="palyazo.laszlo@email.hu"
											:class="{ 'has-error': !!errors.email}"
										/>
										<ErrorMessage class="error-msg" name="email" />
									</div>

									<div class="col-span-6 sm:col-span-4">
										<label for="email">Az igénylő telefonszáma</label>
										<Field
											type="tel"
											name="phone"
											id="phone"
											:rules="phoneValidation"
											@keypress="justNumber($event)"
											v-model="form.phone"
											placeholder="06301231234"
											:class="{ 'has-error': !!errors.phone}"
										/>
										<p class="mt-1 text-sm text-gray-600"> Ha lehetséges mobil telefonszámot adjon meg.<br>A telefonszámot 06 vagy 36-tal kezdje. </p>
										<ErrorMessage class="error-msg" name="phone" />

									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="hidden sm:block" aria-hidden="true">
				<div class="py-5">
					<div class="border-t border-gray-200"></div>
				</div>
			</div>

			<div class="md:grid md:grid-cols-3 md:gap-6 mt-6 sm:mt-0">
				<div class="md:col-span-1">
					<div class="px-4 sm:px-0">
						<h3 class="text-lg font-medium leading-6 text-gray-900">
							Apuka adatai
						</h3>
						<p class="mt-1 text-sm text-gray-600">
							
						</p>
					</div>
				</div>
				<div class="mt-5 md:mt-0 md:col-span-2">
					<div class="shadow sm:rounded-md sm:overflow-hidden">
						<div class="px-4 py-5 bg-white space-y-6 sm:p-6 border-amber-500 border-2 rounded-md">
							<div class="grid grid-cols-6 gap-10">
								<div class="col-span-6 sm:col-span-3">

									<label for="father_fullname">Az apuka teljes neve</label>
									<Field
										type="text"
										name="father_fullname"
										id="father_fullname"
										rules="required" 
										v-model="form.father_fullname"
										placeholder="Pályázó László"
										:class="{ 'has-error': !!errors.father_fullname}"
									/>
									<ErrorMessage class="error-msg" name="father_fullname" />

								</div>
								<div class="col-span-6 sm:col-span-4">

									<label for="father_address">Az apuka lakcíme</label>
									<Field
										type="text"
										name="father_address"
										id="father_address"
										rules="required" 
										v-model="form.father_address"
										placeholder="1234 Budapest Minta utca 43. 4/2"
										:class="{ 'has-error': !!errors.father_address}"
									/>
									<p class="mt-1 text-sm text-gray-600">
										Lakcímkártyán szereplő lakóhely megjelölése az elfogadható, tartózkodási hely nem megfelelő!
									</p>
									<ErrorMessage class="error-msg" name="father_address" />

								</div>

								<div class="col-span-6 sm:col-span-6">
									<div>
										<legend class="text-base font-medium text-gray-900">
											Apuka magyar állampolgár?
										</legend>
										<p class="text-sm text-gray-500">
											<ErrorMessage class="error-msg" name="father_hungarian" /> Kattintson a megfelelő válaszra
										</p>
									</div>
									<div class="mt-2">
										<div class="radio-check">
											<Field
												type="radio"
												name="father_hungarian"
												id="father_hungarian_no"
												rules="required" 
												v-model="form.father_hungarian"
												value="Nem"
											/>
											
											<label
												for="father_hungarian_no"
												:class="['mr-3 block text-sm font-medium text-gray-700', !!errors.father_hungarian ? 'has-error' : '']"
											>
												Nem.
											</label>

											<Field
												type="radio"
												name="father_hungarian"
												id="father_hungarian_yes"
												rules="required" 
												v-model="form.father_hungarian"
												value="Igen"
											/>
											<label
												for="father_hungarian_yes"
												:class="['mr-3 block text-sm font-medium text-gray-700', !!errors.father_hungarian ? 'has-error' : '']"
											>
												Igen.
											</label>
										</div>
										
									</div>
								</div>

								<div class="col-span-6 sm:col-span-6">
									<div>
										<legend class="text-base font-medium text-gray-900">
											Van bejelentett munkahelye?
										</legend>
										<p class="text-sm text-gray-500">
											<ErrorMessage class="error-msg" name="father_job" /> Kattintson a megfelelő válaszra
										</p>
									</div>
									<div class="mt-2">
										<div class="radio-check">
											<Field
												type="radio"
												name="father_job"
												id="father_job_no"
												rules="required" 
												v-model="form.father_job"
												value="Nem"
											/>
											
											<label
												for="father_job_no"
												:class="['mr-3 block text-sm font-medium text-gray-700', !!errors.father_job ? 'has-error' : '']"
											>
												Nem, nincs.
											</label>

											<Field
												type="radio"
												name="father_job"
												id="father_job_yes"
												rules="required" 
												v-model="form.father_job"
												value="Igen"
											/>
											<label
												for="father_job_yes"
												:class="['mr-3 block text-sm font-medium text-gray-700', !!errors.father_job ? 'has-error' : '']"
											>
												Igen, van.
											</label>
										</div>
										
										<Field
											as="textarea"
											name="father_job_comment"
											placeholder="Megjegyzés a munkahelyhez. (Nem kötelező kitölteni)"
										/>
									</div>
								</div>
								<div class="col-span-6 sm:col-span-6">
									<div>
										<legend class="text-base font-medium text-gray-900">
											Apukának van az adóhatóság felé köztartozása?
										</legend>
										<p class="text-sm text-gray-500">
											<ErrorMessage class="error-msg" name="father_owe" /> Kattintson a megfelelő válaszra
										</p>
									</div>
									<div class="mt-2">
										<div class="radio-check">
											
											<Field
												type="radio"
												name="father_owe"
												id="father_owe_no"
												rules="required" 
												v-model="form.father_owe"
												value="Nem"
											/>
											
											<label
												for="father_owe_no"
												:class="['mr-3 block text-sm font-medium text-gray-700', !!errors.father_owe ? 'has-error' : '']"
											>
												Nem, nincs.
											</label>

											<Field
												type="radio"
												name="father_owe"
												id="father_owe_yes"
												rules="required" 
												v-model="form.father_owe"
												value="Igen"
											/>
											<label
												for="father_owe_yes"
												:class="['mr-3 block text-sm font-medium text-gray-700', !!errors.father_owe ? 'has-error' : '']"
											>
												Igen, van.
											</label>

										</div>
									</div>
								</div>

								<div class="col-span-6 sm:col-span-4">

									<label for="father_address">Apuka tulajdoni hányada a fejleszteni kívánt ingatlanban?</label>
									<Field
										type="text"
										name="father_ownership"
										id="father_ownership"
										rules="required" 
										v-model="form.father_ownership"
										placeholder="50%"
										:class="{ 'has-error': !!errors.father_ownership}"
									/>
									<p class="mt-1 text-sm text-gray-600">
										A tulajdoni lapon szereplő hivatalos adat.
									</p>
									<ErrorMessage class="error-msg" name="father_ownership" />
									
								</div>

								<div class="col-span-6 sm:col-span-6">
									<div>
										<legend class="text-base font-medium text-gray-900">
											Apuka legalább egy éve a fejleszteni kívánt ingatlanba van bejelentve?
										</legend>
										<p class="text-sm text-gray-500">
											<ErrorMessage class="error-msg" name="father_loggedin" /> Kattintson a megfelelő válaszra.
										</p>
									</div>
									<div class="mt-2">
										<div class="radio-check">

											<Field
												type="radio"
												name="father_loggedin"
												id="father_loggedin_no"
												rules="required" 
												v-model="form.father_loggedin"
												value="Nem"
												@click="form.father_loggedin_else = ''"
											/>
											
											<label
												for="father_loggedin_no"
												:class="['mr-3 block text-sm font-medium text-gray-700', !!errors.father_loggedin ? 'has-error' : '']"
											>
												Nem, kevesebb mint egy éve.
											</label>

											<Field
												type="radio"
												name="father_loggedin"
												id="father_loggedin_yes"
												rules="required" 
												v-model="form.father_loggedin"
												value="Igen"
												@click="form.father_loggedin_else = ''"
											/>
											<label
												for="father_loggedin_yes"
												:class="['mr-3 block text-sm font-medium text-gray-700', !!errors.father_loggedin ? 'has-error' : '']"
											>
												Igen, több mint egy éve.
											</label>

										</div>
									</div>
								</div>

								<div class="col-span-6 sm:col-span-6" v-if="form.father_loggedin == 'Nem'">
									<div>
										<legend class="text-base font-medium text-gray-900">
											Ha apuka nem a fejleszteni kívánt ingatlanba van bejelentve, akkor az ingatlant az elmúlt egy éven belül vásárolták?
										</legend>
										<p class="text-sm text-gray-500">
											<ErrorMessage class="error-msg" name="father_loggedin_else" /> Kattintson a megfelelő válaszra.
										</p>
									</div>
									<div class="mt-2">
										<div class="radio-check">

											<Field
												type="radio"
												name="father_loggedin_else"
												id="father_loggedin_else_no"
												rules="confirmed:@father_loggedin"
												v-model="form.father_loggedin_else"
												value="Nem"
											/>
											
											<label
												for="father_loggedin_else_no"
												:class="['mr-3 block text-sm font-medium text-gray-700', !!errors.father_loggedin_else ? 'has-error' : '']"
											>
												Nem, több mint egy éve.
											</label>

											<Field
												type="radio"
												name="father_loggedin_else"
												id="father_loggedin_else_yes"
												rules="confirmed:@father_loggedin"
												v-model="form.father_loggedin_else"
												value="Igen"
											/>
											<label
												for="father_loggedin_else_yes"
												:class="['mr-3 block text-sm font-medium text-gray-700', !!errors.father_loggedin_else ? 'has-error' : '']"
											>
												Igen, kevesebb mint egy éve.
											</label>

										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="hidden sm:block" aria-hidden="true">
				<div class="py-5">
					<div class="border-t border-gray-200"></div>
				</div>
			</div>

			<div class="md:grid md:grid-cols-3 md:gap-6 mt-6 sm:mt-0">
				<div class="md:col-span-1">
					<div class="px-4 sm:px-0">
						<h3 class="text-lg font-medium leading-6 text-gray-900">
							Anyuka adatai
						</h3>
						<p class="mt-1 text-sm text-gray-600">
							
						</p>
					</div>
				</div>
				<div class="mt-5 md:mt-0 md:col-span-2">
					<div class="shadow sm:rounded-md sm:overflow-hidden">
						<div class="px-4 py-5 bg-white space-y-6 sm:p-6 border-amber-500 border-2 rounded-md">
							<div class="grid grid-cols-6 gap-10">
								<div class="col-span-6 sm:col-span-3">

									<label for="mother_fullname">Az anyuka teljes neve</label>
									<Field
										type="text"
										name="mother_fullname"
										id="mother_fullname"
										rules="required" 
										v-model="form.mother_fullname"
										placeholder="Pályázó Mária"
										:class="{ 'has-error': !!errors.mother_fullname}"
									/>
									<ErrorMessage class="error-msg" name="mother_fullname" />

								</div>
								<div class="col-span-6 sm:col-span-4">

									<label for="mother_address">Az anyuka lakcíme</label>
									<Field
										type="text"
										name="mother_address"
										id="mother_address"
										rules="required" 
										v-model="form.mother_address"
										placeholder="1234 Budapest Minta utca 43. 4/2"
										:class="{ 'has-error': !!errors.mother_address}"
									/>
									<p class="mt-1 text-sm text-gray-600">
										Lakcímkártyán szereplő lakóhely megjelölése az elfogadható, tartózkodási hely nem megfelelő!
									</p>
									<ErrorMessage class="error-msg" name="mother_address" />

								</div>

								<div class="col-span-6 sm:col-span-6">
									<div>
										<legend class="text-base font-medium text-gray-900">
											Anyuka magyar állampolgár?
										</legend>
										<p class="text-sm text-gray-500">
											<ErrorMessage class="error-msg" name="mother_hungarian" /> Kattintson a megfelelő válaszra
										</p>
									</div>
									<div class="mt-2">
										<div class="radio-check">
											<Field
												type="radio"
												name="mother_hungarian"
												id="mother_hungarian_no"
												rules="required" 
												v-model="form.mother_hungarian"
												value="Nem"
											/>
											
											<label
												for="mother_hungarian_no"
												:class="['mr-3 block text-sm font-medium text-gray-700', !!errors.mother_hungarian ? 'has-error' : '']"
											>
												Nem.
											</label>

											<Field
												type="radio"
												name="mother_hungarian"
												id="mother_hungarian_yes"
												rules="required" 
												v-model="form.mother_hungarian"
												value="Igen"
											/>
											<label
												for="mother_hungarian_yes"
												:class="['mr-3 block text-sm font-medium text-gray-700', !!errors.mother_hungarian ? 'has-error' : '']"
											>
												Igen.
											</label>
										</div>
										
									</div>
								</div>

								<div class="col-span-6 sm:col-span-6">
									<div>
										<legend class="text-base font-medium text-gray-900">
											Van bejelentett munkahelye?
										</legend>
										<p class="text-sm text-gray-500">
											<ErrorMessage class="error-msg" name="mother_job" /> Kattintson a megfelelő válaszra
										</p>
									</div>
									<div class="mt-2">
										<div class="radio-check">
											<Field
												type="radio"
												name="mother_job"
												id="mother_job_no"
												rules="required" 
												v-model="form.mother_job"
												value="Nem"
											/>
											
											<label
												for="mother_job_no"
												:class="['mr-3 block text-sm font-medium text-gray-700', !!errors.mother_job ? 'has-error' : '']"
											>
												Nem, nincs.
											</label>

											<Field
												type="radio"
												name="mother_job"
												id="mother_job_yes"
												rules="required" 
												v-model="form.mother_job"
												value="Igen"
											/>
											<label
												for="mother_job_yes"
												:class="['mr-3 block text-sm font-medium text-gray-700', !!errors.mother_job ? 'has-error' : '']"
											>
												Igen, van.
											</label>
										</div>
										
										<Field
											as="textarea"
											name="mother_job_comment"
											placeholder="Megjegyzés a munkahelyhez. (Nem kötelező kitölteni)"
										/>
									</div>
								</div>
								<div class="col-span-6 sm:col-span-6">
									<div>
										<legend class="text-base font-medium text-gray-900">
											Anyukának van az adóhatóság felé köztartozása?
										</legend>
										<p class="text-sm text-gray-500">
											<ErrorMessage class="error-msg" name="mother_owe" /> Kattintson a megfelelő válaszra
										</p>
									</div>
									<div class="mt-2">
										<div class="radio-check">
											
											<Field
												type="radio"
												name="mother_owe"
												id="mother_owe_no"
												rules="required" 
												v-model="form.mother_owe"
												value="Nem"
											/>
											
											<label
												for="mother_owe_no"
												:class="['mr-3 block text-sm font-medium text-gray-700', !!errors.mother_owe ? 'has-error' : '']"
											>
												Nem, nincs.
											</label>

											<Field
												type="radio"
												name="mother_owe"
												id="mother_owe_yes"
												rules="required" 
												v-model="form.mother_owe"
												value="Igen"
											/>
											<label
												for="mother_owe_yes"
												:class="['mr-3 block text-sm font-medium text-gray-700', !!errors.mother_owe ? 'has-error' : '']"
											>
												Igen, van.
											</label>

										</div>
									</div>
								</div>

								<div class="col-span-6 sm:col-span-4">

									<label for="mother_address">Anyuka tulajdoni hányada a fejleszteni kívánt ingatlanban?</label>
									<Field
										type="text"
										name="mother_ownership"
										id="mother_ownership"
										rules="required" 
										v-model="form.mother_ownership"
										placeholder="50%"
										:class="{ 'has-error': !!errors.mother_ownership}"
									/>
									<p class="mt-1 text-sm text-gray-600">
										A tulajdoni lapon szereplő hivatalos adat.
									</p>
									<ErrorMessage class="error-msg" name="mother_ownership" />
									
								</div>

								<div class="col-span-6 sm:col-span-6">
									<div>
										<legend class="text-base font-medium text-gray-900">
											Anyuka legalább egy éve a fejleszteni kívánt ingatlanba van bejelentve?
										</legend>
										<p class="text-sm text-gray-500">
											<ErrorMessage class="error-msg" name="mother_loggedin" /> Kattintson a megfelelő válaszra.
										</p>
									</div>
									<div class="mt-2">
										<div class="radio-check">

											<Field
												type="radio"
												name="mother_loggedin"
												id="mother_loggedin_no"
												rules="required" 
												v-model="form.mother_loggedin"
												value="Nem"
												@click="form.mother_loggedin_else = ''"
											/>
											
											<label
												for="mother_loggedin_no"
												:class="['mr-3 block text-sm font-medium text-gray-700', !!errors.mother_loggedin ? 'has-error' : '']"
											>
												Nem, kevesebb mint egy éve.
											</label>

											<Field
												type="radio"
												name="mother_loggedin"
												id="mother_loggedin_yes"
												rules="required" 
												v-model="form.mother_loggedin"
												value="Igen"
												@click="form.mother_loggedin_else = ''"
											/>
											<label
												for="mother_loggedin_yes"
												:class="['mr-3 block text-sm font-medium text-gray-700', !!errors.mother_loggedin ? 'has-error' : '']"
											>
												Igen, több mint egy éve.
											</label>

										</div>
									</div>
								</div>

								<div class="col-span-6 sm:col-span-6" v-if="form.mother_loggedin == 'Nem'">
									<div>
										<legend class="text-base font-medium text-gray-900">
											Ha anyuka nem a fejleszteni kívánt ingatlanba van bejelentve, akkor az ingatlant az elmúlt egy éven belül vásárolták?
										</legend>
										<p class="text-sm text-gray-500">
											<ErrorMessage class="error-msg" name="mother_loggedin_else" /> Kattintson a megfelelő válaszra.
										</p>
									</div>
									<div class="mt-2">
										<div class="radio-check">

											<Field
												type="radio"
												name="mother_loggedin_else"
												id="mother_loggedin_else_no"
												rules="confirmed:@mother_loggedin"
												v-model="form.mother_loggedin_else"
												value="Nem"
											/>
											
											<label
												for="mother_loggedin_else_no"
												:class="['mr-3 block text-sm font-medium text-gray-700', !!errors.mother_loggedin_else ? 'has-error' : '']"
											>
												Nem, több mint egy éve.
											</label>

											<Field
												type="radio"
												name="mother_loggedin_else"
												id="mother_loggedin_else_yes"
												rules="confirmed:@mother_loggedin"
												v-model="form.mother_loggedin_else"
												value="Igen"
											/>
											<label
												for="mother_loggedin_else_yes"
												:class="['mr-3 block text-sm font-medium text-gray-700', !!errors.mother_loggedin_else ? 'has-error' : '']"
											>
												Igen, kevesebb mint egy éve.
											</label>

										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="hidden sm:block" aria-hidden="true">
				<div class="py-5">
					<div class="border-t border-gray-200"></div>
				</div>
			</div>

			<div class="mt-10 sm:mt-0">
				<div class="md:grid md:grid-cols-3 md:gap-6 mt-6 sm:mt-0">
					<div class="md:col-span-1">
						<div class="px-4 sm:px-0">
							<h3 class="text-lg font-medium leading-6 text-gray-900">
								A gyermek(ek) adatai
							</h3>
							<p class="mt-1 text-sm text-gray-600">
								Minden gyermek adatát meg kell adni.
							</p>
						</div>
					</div>
					<div class="mt-5 md:mt-0 md:col-span-2">
						<div class="shadow overflow-hidden sm:rounded-md">
							<div class="px-4 py-5 bg-white space-y-6 sm:p-6 border-amber-500 border-2 rounded-md">
								<div class="grid grid-cols-1 text-center">
									<div class="col-span-6 sm:col-span-3">

										<label for="children">Gyermekeinek száma?</label>
										<Field
											type="tel"
											inputmode="numeric"
											pattern="[0-9]*"
											name="children"
											id="children"
											rules="required|numeric|min_value:0"
											v-model="form.children"
											:class="{ 'has-error': !!errors.children}"
											@keypress="justNumber($event)"
										/>
										<ErrorMessage class="error-msg" name="children" />
										
									</div>
								</div>
								<div v-if="typeof +this.form.children == 'number' && +this.form.children > 0">
									<div 
										class="col-span-6 sm:col-span-6" 
										v-for="n in childrens" :key="n"
									>

										<label :for="'children_' + n + '_age'">{{'Gyermek(' + (n+1) + ') életkora'}}</label>
										<Field
											type="tel"
											:name="'children_' + n + '_age'"
											:id="'children_' + n + '_age'"
											rules="required|numeric"
											v-model="form.children_ages[n]"
											:class="{ 'has-error': !!errors['children_' + n + '_age']}"
										/>
										<ErrorMessage class="error-msg" :name="'children_' + n + '_age'" />

										<br>

										<label :for="'children_' + n + '_address'">{{'Gyermek(' + (n+1) + ') lakcíme'}}</label>
										<Field
											type="text"
											:name="'children_' + n + '_address'"
											:id="'children_' + n + '_address'"
											rules="required"
											v-model="form.children_addresses[n]"
											placeholder="1234 Budapest Minta utca 43. 4/2"
											:class="{ 'has-error': !!errors['children_' + n + '_address']}"
										/>
										<p class="mt-1 text-sm text-gray-600">
											Lakcímkártyán szereplő lakóhely megjelölése az elfogadható, tartózkodási hely nem megfelelő!
										</p>
										<ErrorMessage class="error-msg" :name="'children_' + n + '_address'" />

										<br>

										<label :for="'children_' + n + '_ownership'">{{'Gyermek(' + (n+1) + ') tulajdoni hányada az ingatlanban'}}</label>
										<Field
											type="text"
											:name="'children_' + n + '_ownership'"
											:id="'children_' + n + '_ownership'"
											rules="required" 
											v-model="form.children_ownership[n]"
											placeholder="50%"
											:class="{ 'has-error': !!errors['children_' + n + '_ownership']}"
										/>
										<p class="mt-1 text-sm text-gray-600">
											A tulajdoni lapon szereplő hivatalos adat.
										</p>
										<ErrorMessage class="error-msg" :name="'children_' + n + '_ownership'" />

										<div class="sm:block" aria-hidden="true">
											<div class="py-10 md:py-5">
												<div class="border-t border-gray-500"></div>
											</div>
										</div>
										
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="hidden sm:block" aria-hidden="true">
				<div class="py-5">
					<div class="border-t border-gray-200"></div>
				</div>
			</div>

			<div class="mt-10 sm:mt-0">
				<div class="md:grid md:grid-cols-3 md:gap-6 mt-6 sm:mt-0">
					<div class="md:col-span-1">
						<div class="px-4 sm:px-0">
							<h3 class="text-lg font-medium leading-6 text-gray-900">
								Egyéb adatok
							</h3>
							<p class="mt-1 text-sm text-gray-600">

							</p>
						</div>
					</div>
					<div class="mt-5 md:mt-0 md:col-span-2">
						<div class="shadow overflow-hidden sm:rounded-md">
							<div class="px-4 py-5 bg-white space-y-6 sm:p-6 border-amber-500 border-2 rounded-md">
								<div class="grid grid-cols-6 gap-6">
									<div class="col-span-6 sm:col-span-6">
										<div>
											<legend class="text-base font-medium text-gray-900">
												Apuka és anyuka házasok, vagy bejegyzett élettársak?
											</legend>
											<p class="text-sm text-gray-500">
												<ErrorMessage class="error-msg" name="marriage" /> Kattintson a megfelelő válaszra.
											</p>
										</div>
										<div class="mt-2">
											<div class="radio-check">

												<Field
													type="radio"
													name="marriage"
													id="marriage_no"
													rules="required" 
													v-model="form.marriage"
													value="Nem"
												/>
												
												<label
													for="marriage_no"
													:class="['mr-3 block text-sm font-medium text-gray-700', !!errors.marriage ? 'has-error' : '']"
												>
													Nem.
												</label>

												<Field
													type="radio"
													name="marriage"
													id="marriage_yes"
													rules="required" 
													v-model="form.marriage"
													value="Igen"
												/>
												<label
													for="marriage_yes"
													:class="['mr-3 block text-sm font-medium text-gray-700', !!errors.marriage ? 'has-error' : '']"
												>
													Igen.
												</label>

											</div>
										</div>
									</div>

									<div class="col-span-6 sm:col-span-6">
										<div>
											<legend class="text-base font-medium text-gray-900">
												Apuka és anyuka együtt nevelik gyermeküket/gyermekeiket?
											</legend>
											<p class="text-sm text-gray-500">
												<ErrorMessage class="error-msg" name="parenting" /> Kattintson a megfelelő válaszra.
											</p>
										</div>
										<div class="mt-2">
											<div class="radio-check">
												
												<Field
													type="radio"
													name="parenting"
													id="parenting_no"
													rules="required" 
													v-model="form.parenting"
													value="Nem"
												/>
												
												<label
													for="parenting_no"
													:class="['mr-3 block text-sm font-medium text-gray-700', !!errors.parenting ? 'has-error' : '']"
												>
													Nem.
												</label>

												<Field
													type="radio"
													name="parenting"
													id="parenting_yes"
													rules="required" 
													v-model="form.parenting"
													value="Igen"
												/>
												<label
													for="parenting_yes"
													:class="['mr-3 block text-sm font-medium text-gray-700', !!errors.parenting ? 'has-error' : '']"
												>
													Igen.
												</label>

											</div>
										</div>
									</div>

									<div class="col-span-6 sm:col-span-6">
										<div>
											<legend class="text-base font-medium text-gray-900">
												Az ingatlan lakhatási célú ingatlan?
											</legend>
											<p class="text-sm text-gray-500">
												<ErrorMessage class="error-msg" name="residential" /> Kattintson a megfelelő válaszra.
											</p>
										</div>
										<div class="mt-2">
											<div class="radio-check">

												<Field
													type="radio"
													name="residential"
													id="residential_no"
													rules="required" 
													v-model="form.residential"
													value="Nem"
												/>
												
												<label
													for="residential_no"
													:class="['mr-3 block text-sm font-medium text-gray-700', !!errors.residential ? 'has-error' : '']"
												>
													Nem.
												</label>

												<Field
													type="radio"
													name="residential"
													id="residential_yes"
													rules="required" 
													v-model="form.residential"
													value="Igen"
												/>
												<label
													for="residential_yes"
													:class="['mr-3 block text-sm font-medium text-gray-700', !!errors.residential ? 'has-error' : '']"
												>
													Igen.
												</label>

											</div>
										</div>
									</div>

									<div class="col-span-6 sm:col-span-6">
										<div>
											<legend class="text-base font-medium text-gray-900">
												Tudatában vannak, hogy a támogatás utófinanszírozású?
											</legend>
											<p class="text-sm text-gray-500">
												<ErrorMessage class="error-msg" name="post_financing" /> Kattintson a megfelelő válaszra.
											</p>
										</div>
										<div class="mt-2">
											<div class="radio-check">
												
												<Field
													type="radio"
													name="post_financing"
													id="post_financing_no"
													rules="required" 
													v-model="form.post_financing"
													value="Nem"
												/>
												
												<label
													for="post_financing_no"
													:class="['mr-3 block text-sm font-medium text-gray-700', !!errors.post_financing ? 'has-error' : '']"
												>
													Nem.
												</label>

												<Field
													type="radio"
													name="post_financing"
													id="post_financing_yes"
													rules="required" 
													v-model="form.post_financing"
													value="Igen"
												/>
												<label
													for="post_financing_yes"
													:class="['mr-3 block text-sm font-medium text-gray-700', !!errors.post_financing ? 'has-error' : '']"
												>
													Igen.
												</label>

											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="hidden sm:block" aria-hidden="true">
				<div class="py-5">
					<div class="border-t border-gray-200"></div>
				</div>
			</div>

			<div class="mt-10 sm:mt-0">
				<div class="md:grid md:grid-cols-3 md:gap-6 mt-6 sm:mt-0">
					<div class="md:col-span-1">
						<div class="px-4 sm:px-0">
							<h3 class="text-lg font-medium leading-6 text-gray-900 text-center sm:text-left">
								
							</h3>
							<p class="mt-1 text-sm text-gray-600">
								
							</p>
						</div>
					</div>
					<div class="mt-5 md:mt-0 md:col-span-2">
						<label for="comment">Kérjük amennyiben egyéb fontos információi vannak ossza meg velünk!</label>
						<Field
							as="textarea"
							name="comment"
							placeholder="Bármilyen megjegyzés. (Nem kötelező kitölteni)"
							v-model="form.comment"
							rows="5"
						/>
					</div>
				</div>
			</div>

			<div class="hidden sm:block" aria-hidden="true">
				<div class="py-5">
					<div class="border-t border-gray-200"></div>
				</div>
			</div>

			<div class="mt-10 sm:mt-0">
				<div class="md:grid md:grid-cols-3 md:gap-6 mt-6 sm:mt-0">
					<div class="md:col-span-1">
						<div class="px-4 sm:px-0">
							<h3 class="text-l font-medium leading-6 text-gray-900 text-center sm:text-left">
								Elküldés előtt kérjük ellenőrizze a beírt adatok pontosságát és helyességét!
							</h3>
							<p class="mt-1 text-sm text-gray-600">
								
							</p>
						</div>
					</div>
					<div class="mt-5 md:mt-0 md:col-span-2">
						<div class="flex py-3 items-center leading-3">
							<div class="">
								<Field
									type="checkbox"
									name="terms"
									id="terms"
									rules="required"
									value="terms"
								/>
							</div>
							<div class="ml-4">
								<label for="terms">
									Elfogadom az <a class="text-amber-500" href="/files/PM_Adatkezelo.pdf" target="_blank">Adatvédelmi Szabályzatot</a>.
								</label>
							</div>
						</div>
						<ErrorMessage class="error-msg" name="terms" />

						<div class="flex py-3 items-center leading-3">
							<div class="">
								<Field
									type="checkbox"
									name="ack"
									id="ack"
									rules="required"
									value="ack"
								/>
							</div>
							<div class="ml-4">
								<label for="ack">
									Tudomásul veszem, hogy a Pályázatokmindenkinek.hu nem vállal felelőséget a pályázati adatok helyességért!
								</label>
							</div>
						</div>
						<ErrorMessage class="error-msg" name="ack" />
					</div>
				</div>
			</div>
			<div class="block" aria-hidden="true">
				<div class="py-5 mt-2 text-center">
					<button
						class="inline-flex justify-center py-4 px-6 border border-transparent shadow-sm text-lg font-medium rounded-md text-white bg-amber-300 hover:bg-amber-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-400 transition duration-300"
					>
						Mentés és Küldés
					</button>
					<div class="error-msg mt-4" v-if="Object.keys(errors).length">
						Kérjük fent ellenőrizze a pirossal jelölt mezőket.
					</div>
				</div>
			</div>

			<!-- {{form}}
			<br>
			<br>
			{{errors}} -->
			
			<!-- <template v-if="Object.keys(errors).length">
				<p>Kérjük javítsa az alábbi hibákat:</p>
				<ul>
					<li v-for="(message, field) in errors" :key="field">
						{{ field+" - "+message }}
					</li>
				</ul>
			</template> -->
		
		</div>
		<div 
			v-else 
			key="afterSubmit"
			class="my-10 max-w-6xl mx-auto p-0 md:p-6 text-2xl leading-7 md:leading-8 text-center"
		>
			<svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"><circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none"/><path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/></svg>
			Köszönjük, hogy kitöltötte a szűrőlapot!
			<br>
			E-mailben a válaszait és további információkat küldtünk Önnek.
		</div>
	</transition>
</Form>
</template>

<script>
import axios from 'axios';
import { defineRule, configure, Field, Form, ErrorMessage } from 'vee-validate';
import { required, email, min, max, numeric, min_value, regex } from '@vee-validate/rules';
import { localize, setLocale } from '@vee-validate/i18n';
import hu from '@/assets/vee_validate_hu';

/* import Input from './components/Input'; */

defineRule('required', required);
defineRule('email', email);
defineRule('min', min);
defineRule('max', max);
defineRule('numeric', numeric);
defineRule('min_value', min_value);
defineRule('regex', regex);
defineRule('confirmed', (value, [other]) => {
  if (other == "Nem" && value == "") {
    return `Az előző kérdésre adott "Nem" válasz esetén ez a mező kötelezően kitöltendő.`;
  }
  return true;
});
configure({
	/* validateOnInput: true, */
	generateMessage: localize({
		hu
	}),
});
setLocale('hu');

axios.defaults.baseURL = process.env.VUE_APP_API_URL;

export default {
	components: {
		Field,
		Form,
		ErrorMessage,
		/* Input, */
	},
	data() {
		return {
			phoneValidation: { 
				required: true,
				min: 8,
				regex: /((?:\+?3|0)6)(?:-|\()?(\d{1,2})(?:-|\))?(\d{3})-?(\d{3,4})/,
				max: 14
			},
			form: {
				fullname: "",
				email: "",
				phone: "",
				father_fullname: "",
				father_address: "",
				father_hungarian: "",
				father_job: "",
				father_job_comment: "",
				father_owe: "",
				father_ownership: "",
				father_loggedin: "",
				father_loggedin_else: "",
				mother_fullname: "",
				mother_address: "",
				mother_hungarian: "",
				mother_job: "",
				mother_job_comment: "",
				mother_owe: "",
				mother_ownership: "",
				mother_loggedin: "",
				mother_loggedin_else: "",
				children: 0,
				children_ages: [],
				children_addresses: [],
				children_ownership: [],
				marriage: "",
				parenting: "",
				residential: "",
				post_financing: "",
				comment: ""
			},
			submited: false
		};
	},
	computed: {
		childrens() {
			return [...Array(+this.form.children).keys()];
		}
	},
	methods: {
		save() {
			let form = this.form;
			axios.post(
				'otthon_handler.php',
				{form}
			)
			.then((response) => {
				console.log(response);
				this.submited = true;
			}, 
			(error) => {
				console.log(error);
			});
		},
		justNumber(e) {
			let char = String.fromCharCode(e.keyCode);
			if (/^[0-9]+$/.test(char)) 
				return true;
			else 
				e.preventDefault();
		}
	},
};
</script>

<style lang="scss">
.form-selection-title {
	background-color: transparent;
	background-image: linear-gradient(170deg, #fbb034 0%, #ffdd00 100%);
}

.container {
	border: 3px solid transparent;
	border-image: linear-gradient(#fbb034, #ffdd00);
	border-image-slice: 10;

	input.has-error,
	label.has-error {
		background-color: #fddfe2;
		border-color: transparent;
		color: #f23648;
	}

	.error-msg {
		color: #f23648;
	}

	.radio-check {
		display: flex;
		flex-flow: row wrap;

		label {
			flex: 1;
			margin:0;
			display: flex;
			align-items: center;
			justify-content: center;
			background: white;
			border: 2px solid transparent;
			border-image: linear-gradient(#fbb034, #ffdd00);
			border-image-slice: 10;
			padding: 10px;
			margin-bottom: 1rem;
			text-align: center;
			box-shadow: 0px 3px 10px -2px rgba(161, 170, 166, 0.5);
			position: relative;
			transition: 0.3s;
			font-size: 15px;
		}
		label:nth-of-type(2n) {
			margin-left: 10px;
		}
		label:nth-of-type(2n+1) {
			margin-right: 10px;
		}
	}
	input[type="radio"],
	input[type="checkbox2"] {
		display: none;
	}
	input[type="radio"] ~ label,
	input[type="checkbox2"] ~ label {
		cursor: pointer;
		line-height: 1rem;
	}
	input[type="radio"]:checked + label,
	input[type="checkbox2"]:checked + label {
		background: linear-gradient(170deg, #fbb034 0%, #ffdd00 100%);
		color: white;
		box-shadow: 0px 0px 20px rgba(251, 176, 52, 0.5);
		border-image: linear-gradient(#fbb034, #ffdd00) !important;
	}
	input[type="radio"]:checked + label::after,
	input[type="checkbox2"]:checked + label::after {
		color: #3d3f43;
		border: 2px solid #ffdd00;
		content: "\2713";
		font-size: 15px;
		position: absolute;
		top: -18px;
		left: 50%;
		transform: translateX(-50%);
		height: 30px;
		width: 30px;
		line-height: 28px;
		text-align: center;
		border-radius: 50%;
		background: rgba(255, 255, 255, 0.85);
		box-shadow: 0px 2px 5px -2px rgba(0, 0, 0, 0.25);
	}
}
.fade-enter-active, .fade-leave-active {
	transition: opacity .5s;
}
.fade-enter, .fade-leave-to {
	opacity: 0;
}

.checkmark {
	margin: 0 auto 40px auto;
	width: 56px;
	height: 56px;
	border-radius: 50%;
	display: block;
	stroke-width: 2;
	stroke: #fff;
	stroke-miterlimit: 10;
	box-shadow: inset 0px 0px 0px #14cc11;
	animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
}

.checkmark__circle {
	stroke-dasharray: 166;
	stroke-dashoffset: 166;
	stroke-width: 2;
	stroke-miterlimit: 10;
	stroke: #14cc11;
	fill: none;
	animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark__check {
	transform-origin: 50% 50%;
	stroke-dasharray: 48;
	stroke-dashoffset: 48;
	animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
	100% {
		stroke-dashoffset: 0;
	}
}
@keyframes scale {
	0%, 100% {
		transform: none;
	}
	50% {
		transform: scale3d(1.1, 1.1, 1);
	}
}
@keyframes fill {
	100% {
		box-shadow: inset 0px 0px 0px 30px #14cc11;
	}
}
</style>
