import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

import "@/assets/tailwind.css";

/* import { defineRule } from 'vee-validate';

defineRule('required', value => {
  if (!value || !value.length) {
    return 'This field is required';
  }

  return true;
}); */

createApp(App)
	.use(router)
	.mount("#app");	
